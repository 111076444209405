#easter-egg-2 {
  display: none;
}

#easter-egg-3 {
  color: #f00;
}

#egg-6 {
  display: none;
  margin-bottom: 40px;
}

#egg-6-window {
  background-color: #fff;
  height: 360px;
  position: fixed;
  width: 480px;
}

#egg-7 {
  background-color: #fff;
  bottom: 20px;
  height: 360px;
  position: fixed;
  right: 20px;
  width: 480px;
}

#egg-8 {
  bottom: 100px;
  cursor: pointer;
  display: block;
  float: left;
  left: 50px;
  height: 50px;
  position: relative;
  width: 50px;
}

#egg-8-frame {
  background-color: black;
  border: 10px solid #aaa;
  box-shadow: 0px 10px 10px #000;
  color: #0f0;
  font-family: 'digital-7';
  height: 360px;
  padding: 10px;
  position: fixed;
  width: 480px;
}

.egg-9 {
  border: 20px solid #f00;
  position: fixed;
}