.navbar {
  border-bottom: 1px solid #000;
  font-family: 'Source Serif Pro', serif;
  font-size: 16px;
  will-change: transform;
  a:hover {
    text-decoration: none;
  }
  .navbar-header {
    .icon-menu {
      background: inherit;
      list-style-type: none;
      margin-left: 20px;
      padding: 0;
      position: relative;
      top: 2px;
      li {
        display: inline-block;
        &.active, &:hover {
          a {
            border-bottom: 3px solid #fbd35a;
            padding: 10px;
          }
        }
        a {
          background: inherit;
          padding: 10px;
        }
      }
      .nav-icon {
        height: 24px;
      }
    }
    .navbar-brand {
      color: #fbd35a !important;
      &:hover {
        color: #FFA500 !important; /* Required to override */
      }
    }
  }
  #navbar {
    ul li {
      /* Spacing Between Menu Items */
      margin: 0 6px;
      &:hover {
        a {
          background-color: inherit;
          border-bottom: 3px solid #fbd35a;
          color: #FFA500;
        }
      }
      &.active {
        a {
          background-color: inherit;
          border-bottom: 3px solid #fbd35a;
          /* color: #FFA500; */
        }

      }
      a {
        color: #fbd35a;
        /* Menu Items Sizing */
        padding-bottom: 12px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 15px;
      }
    }
    .addthis_sharing_toolbox {
      display: inline-block;
      #atstbx {
        padding-right: 10px;
        position: relative;
        top: 7px;
      }
    }
  }
}

.nav-target {
  bottom: 30px;
  position: relative;
}

#share-on {
  @extend .sans-serif;
  background-color: #444;
  border-radius: 2px;
  bottom: 4px;
  color: #aaa;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
}

#egg-5 {
  cursor: pointer;
  display: inline-block;
  height: 32px;
  left: 2px;
  position: relative;
  top: 7px;
  width: 32px;
}