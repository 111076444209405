@font-face {
  font-family: digital-7;
  src: url('../fonts/digital-7/digital-7.ttf');
}

/* latin-ext */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Serif Pro'), local('SourceSerifPro-Regular'), url('../fonts/source-serif-pro/source-serif-pro-latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Serif Pro'), local('SourceSerifPro-Regular'), url('../fonts/source-serif-pro/source-serif-pro-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;

}

/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro'), local('SourceSansPro-Regular'), url('../fonts/source-sans-pro/source-sans-pro-latin-vietnamese.woff2') format('woff2');
  unicode-range: U+0102-0103, U+1EA0-1EF1, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro'), local('SourceSansPro-Regular'), url('../fonts/source-sans-pro/source-sans-pro-latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro'), local('SourceSansPro-Regular'), url('../fonts/source-sans-pro/source-sans-pro-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
