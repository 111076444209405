/*
html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 50px;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #222;
}
*/