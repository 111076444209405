@import 'app.scss';

.dark {
  .section-header {
    @extend .opaque-dark;
    color: #fff;
  }
  .disc-container {
    @extend .opaque-dark;
  }
  img, .art-card {
    @extend .opaque-dark;
    .title {
      color: #fbd35a;
    }
  }
  .song-artist {
    color: #FFA500;
  }
  .song-title {
    color: #fbd35a;
  }
}
