@import 'app';

.art-gallery {
  /* Individual piece of artwork */
  .art-wrapper {
    display: inline-block;
    margin-bottom: 40px;
    margin-left: 20px;
    margin-right: 20px;
    a {
      img.artwork {
        padding: 5px;
      }
    }
    .art-card {
      bottom: 5px;
      padding: 5px 10px;
      position: relative;
      z-index: 0;
      .title {
        @extend .serif;
        line-height: 16px;
        margin-bottom: 5px;
        margin-top: 5px;
      }
      .artist {
        @extend .sans-serif;
        font-variant: small-caps;
        line-height: 24px;
      }
    }
  }
}

/* Carousel base class */
.carousel {
  height: 500px;
  margin-bottom: 60px;
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel .item {
  height: 500px;
  background-color: #777;
}

.carousel-inner > .item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 500px;
}
