@import 'app';

#home-content, #home-bg {
  color: #fff;
  font-family: Helvetica;
}

#home-row {
  margin-top: 40px;

  #sidebar {
    @extend .opaque-light;
    margin-bottom: 40px;
    padding: 10px;

    a {
      &:hover, &:active {
        text-decoration: none;
      }
    }

    div:not(:last-child) {
      border-bottom: 1px solid #aaa;
    }

    #album-cover {
      max-width: 800px;
      margin-bottom: 20px;
      width: 100%;
    }

    #store {
      padding-bottom: 10px;

      #itunes {
        height: 35px;
        margin-right: 5px;
      }

      #spotify {
        display: block;
        margin-bottom: 10px;
        max-width: 100%;
        width: 100%;
      }
      #loudr {
        img {
          display: block;
          margin-bottom: 10px;
          max-width: 100%;
          width: 100%;
        }
      }

      .secondary-store {
        img {
          display: inline-block;
          max-width: 45%;
          width: 45%;
        }
      }

    }
    #subscribe-section {
      padding-top: 10px;
      .input {
        @extend .sans-serif;
        padding: 1px 5px;
        margin-bottom: 5px;
        width: 100%;
      }
    }

    #twitter-widget-0 {
      margin-top: 10px !important;
    }

    .sidebar-button {
      @extend .serif;
      background-color: #23296e;
      color: #fff !important;
      width: 100%;
      &:hover {
        color: #FFA500 !important; /* Required to override */
      }
    }

    #social-media {
      padding: 10px;
      vertical-align: top;
      a {
        img {
          width: 100%;
        }
      }
    }

    #email-section {
      padding: 10px 0;
    }
  }

  #home-text {
    @extend .opaque-light;
    @extend .sans-serif;
    font-size: 16px;

    #youtube {
      border: none;
      height: 315px;
      width: 100%;
    }

    #home-text-container {
      border-top: 1px solid #aaa;
      padding-top: 10px;
    }

  }
}

