@charset "UTF-8";
.blueimp-gallery, .blueimp-gallery > .slides > .slide > .slide-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -moz-backface-visibility: hidden
}

.blueimp-gallery > .slides > .slide > .slide-content {
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  opacity: 1
}

.blueimp-gallery {
  position: fixed;
  z-index: 999999;
  overflow: hidden;
  background: #000;
  background: rgba(0, 0, 0, .9);
  opacity: 0;
  display: none;
  direction: ltr;
  -ms-touch-action: none;
  touch-action: none
}

.blueimp-gallery-carousel {
  position: relative;
  z-index: auto;
  margin: 1em auto;
  padding-bottom: 56.25%;
  box-shadow: 0 0 10px #000;
  -ms-touch-action: pan-y;
  touch-action: pan-y
}

.blueimp-gallery-display {
  display: block;
  opacity: 1
}

.blueimp-gallery > .slides {
  position: relative;
  height: 100%;
  overflow: hidden
}

.blueimp-gallery-carousel > .slides {
  position: absolute
}

.blueimp-gallery > .slides > .slide {
  position: relative;
  float: left;
  height: 100%;
  text-align: center;
  -webkit-transition-timing-function: cubic-bezier(.645, .045, .355, 1);
  -moz-transition-timing-function: cubic-bezier(.645, .045, .355, 1);
  -ms-transition-timing-function: cubic-bezier(.645, .045, .355, 1);
  -o-transition-timing-function: cubic-bezier(.645, .045, .355, 1);
  transition-timing-function: cubic-bezier(.645, .045, .355, 1)
}

.blueimp-gallery, .blueimp-gallery > .slides > .slide > .slide-content {
  -webkit-transition: opacity .5s linear;
  -moz-transition: opacity .5s linear;
  -ms-transition: opacity .5s linear;
  -o-transition: opacity .5s linear;
  transition: opacity .5s linear
}

.blueimp-gallery > .slides > .slide-loading {
  background: url(../img/loading.gif) center no-repeat;
  background-size: 64px 64px
}

.blueimp-gallery > .slides > .slide-loading > .slide-content {
  opacity: 0
}

.blueimp-gallery > .slides > .slide-error {
  background: url(../img/error.png) center no-repeat
}

.blueimp-gallery > .slides > .slide-error > .slide-content {
  display: none
}

.blueimp-gallery > .next, .blueimp-gallery > .prev {
  position: absolute;
  top: 50%;
  left: 15px;
  width: 40px;
  height: 40px;
  margin-top: -23px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 60px;
  font-weight: 100;
  line-height: 30px;
  color: #fff;
  text-decoration: none;
  text-shadow: 0 0 2px #000;
  text-align: center;
  background: #222;
  background: rgba(0, 0, 0, .5);
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 3px solid #fff;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  opacity: .5;
  cursor: pointer;
  display: none
}

.blueimp-gallery > .next {
  left: auto;
  right: 15px
}

.blueimp-gallery > .close, .blueimp-gallery > .title {
  position: absolute;
  top: 15px;
  left: 15px;
  margin: 0 40px 0 0;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  text-shadow: 0 0 2px #000;
  opacity: .8;
  display: none
}

.blueimp-gallery > .close {
  padding: 15px;
  right: 15px;
  left: auto;
  margin: -15px;
  font-size: 30px;
  text-decoration: none;
  cursor: pointer
}

.blueimp-gallery > .play-pause {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 15px;
  height: 15px;
  background: url(../img/play-pause.png) 0 0 no-repeat;
  cursor: pointer;
  opacity: .5;
  display: none
}

.blueimp-gallery-playing > .play-pause {
  background-position: -15px 0
}

.blueimp-gallery > .close:hover, .blueimp-gallery > .next:hover, .blueimp-gallery > .play-pause:hover, .blueimp-gallery > .prev:hover, .blueimp-gallery > .title:hover {
  color: #fff;
  opacity: 1
}

.blueimp-gallery-controls > .close, .blueimp-gallery-controls > .next, .blueimp-gallery-controls > .play-pause, .blueimp-gallery-controls > .prev, .blueimp-gallery-controls > .title {
  display: block;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0)
}

.blueimp-gallery-left > .prev, .blueimp-gallery-right > .next, .blueimp-gallery-single > .next, .blueimp-gallery-single > .play-pause, .blueimp-gallery-single > .prev {
  display: none
}

.blueimp-gallery > .close, .blueimp-gallery > .next, .blueimp-gallery > .play-pause, .blueimp-gallery > .prev, .blueimp-gallery > .slides > .slide > .slide-content {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

body:last-child .blueimp-gallery > .slides > .slide-error {
  background-image: url(../img/error.svg)
}

body:last-child .blueimp-gallery > .play-pause {
  width: 20px;
  height: 20px;
  background-size: 40px 20px;
  background-image: url(../img/play-pause.svg)
}

body:last-child .blueimp-gallery-playing > .play-pause {
  background-position: -20px 0
}

.blueimp-gallery > .indicator {
  position: absolute;
  top: auto;
  right: 15px;
  bottom: 15px;
  left: 15px;
  margin: 0 40px;
  padding: 0;
  list-style: none;
  text-align: center;
  line-height: 10px;
  display: none
}

.blueimp-gallery > .indicator > li {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin: 6px 3px 0 3px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid transparent;
  background: #ccc;
  background: rgba(255, 255, 255, .25) center no-repeat;
  border-radius: 5px;
  box-shadow: 0 0 2px #000;
  opacity: .5;
  cursor: pointer
}

.blueimp-gallery > .indicator > .active, .blueimp-gallery > .indicator > li:hover {
  background-color: #fff;
  border-color: #fff;
  opacity: 1
}

.blueimp-gallery-controls > .indicator {
  display: block;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0)
}

.blueimp-gallery-single > .indicator {
  display: none
}

.blueimp-gallery > .indicator {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.blueimp-gallery > .slides > .slide > .video-content > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -moz-backface-visibility: hidden
}

.blueimp-gallery > .slides > .slide > .video-content > video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.blueimp-gallery > .slides > .slide > .video-content > iframe {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  border: none
}

.blueimp-gallery > .slides > .slide > .video-playing > iframe {
  top: 0
}

.blueimp-gallery > .slides > .slide > .video-content > a {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: -64px auto 0;
  width: 128px;
  height: 128px;
  background: url(../img/video-play.png) center no-repeat;
  opacity: .8;
  cursor: pointer
}

.blueimp-gallery > .slides > .slide > .video-content > a:hover {
  opacity: 1
}

.blueimp-gallery > .slides > .slide > .video-playing > a, .blueimp-gallery > .slides > .slide > .video-playing > img {
  display: none
}

.blueimp-gallery > .slides > .slide > .video-content > video {
  display: none
}

.blueimp-gallery > .slides > .slide > .video-playing > video {
  display: block
}

.blueimp-gallery > .slides > .slide > .video-loading > a {
  background: url(../img/loading.gif) center no-repeat;
  background-size: 64px 64px
}

body:last-child .blueimp-gallery > .slides > .slide > .video-content:not(.video-loading) > a {
  background-image: url(../img/video-play.svg)
}

/*# sourceMappingURL=blueimp-gallery.min.css.map */