@charset "UTF-8";
.blueimp-gallery .modal-body {
  position: relative;
  text-align: center;
  padding: 0 0 56.25% 0;
  overflow: hidden;
  cursor: pointer
}

.blueimp-gallery .modal-footer {
  margin: 0
}

.blueimp-gallery .modal-body .video-content a, .blueimp-gallery .modal-body .video-content iframe, .blueimp-gallery .modal-body .video-content video, .blueimp-gallery .modal-body img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.blueimp-gallery .modal-body .video-content video {
  display: none
}

.blueimp-gallery .modal-body .video-playing video {
  display: block
}

.blueimp-gallery .modal-body .video-content iframe {
  width: 100%;
  height: 100%;
  border: none;
  left: 100%
}

.blueimp-gallery .modal-body .video-playing iframe {
  left: 0
}

.blueimp-gallery .modal-body .video-playing a, .blueimp-gallery .modal-body .video-playing img {
  display: none
}

.blueimp-gallery .modal-body .video-content a {
  cursor: pointer
}

.blueimp-gallery .modal-body .video-content a:after {
  font-family: "Glyphicons Halflings";
  -webkit-font-smoothing: antialiased;
  content: "\e029";
  font-size: 64px;
  line-height: 64px;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  margin: -32px 0 0 -32px
}

.blueimp-gallery .modal-body .video-loading a {
  background: url(../img/loading.gif) center no-repeat;
  background-size: 64px 64px
}

.blueimp-gallery .modal-body .video-loading a:after {
  content: none
}

/*# sourceMappingURL=bootstrap-image-gallery.min.css.map */