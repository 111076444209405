@import 'app';

.disc-container {
  padding: 20px 15px;
  ol.track-list {
    li {
      margin-bottom: 20px;
      .song-title {
        @extend .serif;
        line-height: 16px;
        margin-bottom: 0;
      }
      .song-artist {
        line-height: 18px;
      }
      ul.performers {
        font-variant: small-caps;
        line-height: 24px;
        list-style-type: none;
        margin-top: 5px;
        padding: 0;
        li {
          margin: 0;
        }
      }
    }
  }
}
