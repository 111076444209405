#team-logo {
  max-width: 1024px;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
}

#team-thanks-subtitle {
  margin-bottom: 80px;
}
#team-content {
  a {
    color: #FFA500 !important;
    &:active, &:hover {
      color: #fbd35a !important;
      text-decoration: none;
    }
  }
  color: #09B6FF;
  font-family: 'Franklin Gothic Medium', 'Franklin Gothic', 'ITC Franklin Gothic', Arial, sans-serif;
  font-stretch: ultra-condensed;
  font-variant: small-caps;
  line-height: 20px;
  text-shadow: 5px 5px 30px rgba(0, 0, 0, 1);
  ul.credits-single-vertical {
    list-style-type: none;
    padding-left: 0;
    li {
      margin-bottom: 80px;
      margin-left: auto;
      margin-right: auto;
      max-width: 400px;
      .credits-single-name {
        margin-bottom: 0;
        margin-top: 0;
      }
      .credits-single-website {
        display: block;
        margin-bottom: 0;
        margin-top: 0;
      }
      .team-list-name {
        margin-bottom: 20px;
      }
    }
  }
  .disc {
    margin-bottom: 80px;
    .team-track {
      padding: 20px;
    }
  }
  .credits-detail {
    font-size: 18px;
  }
}
.tooltip-arrow {
  background-color: #000;
  color: #fff;
}
.tooltip-inner {
  background-color: #fff;
  color: #000;
  font-size: 16px;
  white-space: nowrap;
}
