@import 'themes';

.ant-frozen-skyway {
  background-image: url('../bg-images-min/frozen-skyway.jpg');
  @extend .dark;
}

.ant-omen {
  background-image: url('../bg-images-min/omen.jpg');
  @extend .dark;
}

.ant-zeal {
  background-image: url('../bg-images-min/zeal.jpg');
  @extend .dark;
}

.eot-the-last-stop {
  background-image: url('../bg-images-min/the-last-stop.jpg');
}

.fut-culture-shock {
  background-image: url('../bg-images-min/culture-shock.jpg');
  @extend .dark;
}

.fut-fire-rains-from-the-heavens {
  background-image: url('../bg-images-min/fire-rains-from-the-heavens.jpg');
  @extend .dark;
}

.fut-emergency {
  background-image: url('../bg-images-min/emergency.jpg');
  @extend .dark;
}

.fut-future-imperfect {
  background-image: url('../bg-images-min/future-imperfect.jpg');
  @extend .dark;
}


.fut-our-future {
  background-image: url('../bg-images-min/our-future.jpg');
  @extend .dark;
}

.fut-ride-or-die-baby {
  background-image: url('../bg-images-min/ride-or-die-baby.jpg');
  @extend .dark;
}

.fut-road-hazard {
  background-image: url('../bg-images-min/road-hazard.jpg');
  @extend .dark;
}

.mid-flow-of-the-forest {
  background-image: url('../bg-images-min/flow-of-the-forest.jpg');
  @extend .dark;
}

.mid-magus-castle {
  background-image: url('../bg-images-min/magus-castle.jpg');
  @extend .dark;
}

.mid-robot-leenes-shrine {
  background-image: url('../bg-images-min/robot-leenes-shrine.jpg');
  @extend .dark;
}

.misc-ayla {
  background-image: url('../bg-images-min/ayla.jpg');
  @extend .dark;
}

.misc-frog {
  background-image: url('../bg-images-min/frog.jpg');
  @extend .dark;
}

.misc-bill-watterson {
  background-image: url('../bg-images-min/bill-watterson.jpg');
  @extend .dark;
}

.misc-chrono-vs-magus {
  background-image: url('../bg-images-min/chrono-vs-magus.jpg');
  @extend .dark;
}

.misc-discovering-christmas {
  background-image: url('../bg-images-min/discovering-christmas.jpg');
  @extend .dark;
}

.misc-fire-dance {
  background-image: url('../bg-images-min/fire-dance.jpg');
  @extend .dark;
}

.mid-a-hero-is-born {
  background-image: url('../bg-images-min/a-hero-is-born.jpg');
  @extend .dark;
}

.mid-mountains-are-nice {
  background-image: url('../bg-images-min/mountains-are-nice.jpg');
  @extend .dark;
}

.mid-slithering-shadows {
  background-image: url('../bg-images-min/slithering-shadows.jpg');
  @extend .dark;
}

.preh-smoulder {
  background-image: url('../bg-images-min/smoulder.jpg');
  @extend .dark;
}

.preh-the-fated-hour {
  background-image: url('../bg-images-min/the-fated-hour.jpg');
  @extend .dark;
}

.preh-thats-some-door {
  background-image: url('../bg-images-min/thats-some-door.jpg');
  @extend .dark;
}

.pres-dragon-tank-hanzo {
  background-image: url('../bg-images-min/dragon-tank-hanzo.jpg');
  @extend .dark;
}

.pres-dragon-tank-horsfall {
  background-image: url('../bg-images-min/dragon-tank-horsfall.jpg');
  @extend .dark;
}

.pres-millennial-day {
  background-image: url('../bg-images-min/millennial-day.jpg');
  @extend .dark;
}

.pres-to-far-away-times {
  background-image: url('../bg-images-min/to-far-away-times.jpg');
  @extend .dark;
}

.pres-trial-interrupted-top {
  background-image: url('../bg-images-min/trial-interrupted-top.jpg');
  @extend .dark;
}

.pres-trial-interrupted-bottom {
  background-image: url('../bg-images-min/trial-interrupted-bottom.jpg');
  @extend .dark;
}

.starfield {
  background-image: url('../images/bg_starfield-min.jpg');
}

.tech-omega-flare {
  background-image: url('../bg-images-min/omega-flare.jpg');
  @extend .dark;
}

.tech-slurp {
  background-image: url('../bg-images-min/slurp.jpg');
  @extend .dark;
}

.tech-spire {
  background-image: url('../bg-images-min/spire.jpg');
  @extend .dark;
}
