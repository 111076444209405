body {
  color: #f8f8f8;
  font-size: 18px;
  line-height: 32px;
  @extend .sans-serif;
  a {
    color: #c84f11;
    &:active, &:hover {
      color: #c84f11;
    }
  }
}

.sans-serif {
  font-family: 'Source Sans Pro', sans-serif;
}

.serif {
  font-family: 'Source Serif Pro', serif;
}

.bg-standard {
  border-bottom: 1px solid #000;
  box-shadow: 0px 0px 10px #000 inset;
}

.font-bold {
  font-size: 20px;
  font-weight: bold;
}

.section-content {
  padding-bottom: 40px;
  padding-top: 40px;
}

.section-header {
  @extend .serif;
  margin-bottom: 40px;
  margin-top: 0;
  padding: 20px 0;
  font-variant: small-caps;
}

.section-bg-fixed {
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: 1px solid #000;
  box-shadow: 0px 0px 10px #000 inset;
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}

.section-bg-fixed-hidden {
  display: none;
  position: static;
}

.text-content {
  padding: 10px;
}

.text-small {
  font-size: 14px;
}

.opaque-light {
  background-color: rgba(224, 224, 224, .95);
  border-radius: 2px;
  color: #000;
}

.opaque-dark {
  background-color: rgba(8, 8, 8, .88);
  border-radius: 2px;
  color: #fff;
}

.will-change-enabled {
  will-change: transform;
}

.will-change-disabled {
  will-change: auto;
}
